import { Fragment, useState } from "react";

import { Card, CardBody } from "reactstrap";

import SelectApiInput from "Components/Inputs/SelectApiInput";
import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ImportExcel from "Components/ImportExcel";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";

import { CREATE_WORDING, EDIT_WORDING, MY_WORDING } from "Constant";
import { getCodeLabel } from "helper/getOptions";

const WordingPage = () => {
  const list = API.list;
  const [domain, setDomain] = useState({
    name: "Messages",
    value: "messages",
  });

  const inputName = {
    name: "domain",
    title: "Domain",
    optionsApi: "/back/wordingdomains/choices",
    getOptions: getCodeLabel,
  };

  function handleImportDomain(dom) {
    return (data) => {
      return `{"domain": "${dom}","data":${data}}`;
    };
  }

  const handleImport = handleImportDomain(domain.value);

  const breadCrumbsProps = [MY_WORDING];
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables
            newColumns={COLUMNS}
            link={list}
            globalSearch={false}
            importBtn={true}
          />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_WORDING}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_WORDING}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="import">
        <div style={{ marginLeft: 10 }}>
          <SelectApiInput
            register={setDomain}
            inputName={inputName}
            oldValue="Messages"
          />
        </div>
        <ImportExcel
          importApi={API.import}
          file_name="Wording"
          handleImport={handleImport}
        />
      </ModalApp>
    </Fragment>
  );
};

export default WordingPage;
