import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import { getCodeLabel, getTitleCodeLabel } from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import { MODEL } from "Constant";

// Urls
export const API = {
  list: "/back/sunshields",
  choice: "/back/sunshields/choices",
  Serial: "/back/sunshields/serials",
  create: "/back/sunshield",
  get: "/back/sunshield/",
  edit: "/back/sunshield/",
  patch: "/back/sunshield/",
  import: "/back/sunshields",
};

// Columns
export const COLUMNS = [
  statusObj("material"),
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: MODEL,
    selector: "model",
    id: "model",
  },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, active, serial_number, editable } = row.original;
      return (
        <Group gap="xs">
          <EditBtn
            read={API.get + code}
            extra={serial_number}
            disabled={!editable}
          />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sunshield",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/sunshield",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SimpleInput",
  },
];
