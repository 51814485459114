import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../Services/axiosClient";
import { getDataRows } from "helper/getData";

function organizeByCategory(arrayOpt) {
  try {
    return arrayOpt.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push({
        label: item.label,
        value: item.code,
        ...(item.category !== "contract" && { code: item.code }),
      });
      return acc;
    }, {});
  } catch {
    return {};
  }
}

export const fetchGetOptions = createAsyncThunk(
  "list/fetchOptions",
  async (_, { getState }) => {
    const url = "/back/statuses?index=-1";
    let response = [];
    const { data } = getState().findByFilters; // Access the slice state
    if (!data.length) {
      try {
        response = await axiosClient.get(url);
        return getDataRows(response);
      } catch (error) {
        throw error;
      }
    }
    return data;
  }
);

export const getOptionsSlice = createSlice({
  name: "getOptions",
  initialState: {
    data: [],
    options: [],
    isLoading: false,
    isError: false,
  },

  reducers: {
    insertData: (state, action) => {
      state.options = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetOptions.fulfilled, (state, action) => {
        state.data = action.payload;
        state.options = organizeByCategory(action.payload);
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchGetOptions.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchGetOptions.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { insertData } = getOptionsSlice.actions;

export default getOptionsSlice.reducer;
