import React from "react";

import Translate from "Components/Translate";

import { Menu, rem } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";

const SettingsReadBtn = ({ read: path, disabled, title = "Read" }) => {
  const openInNewTab = () => {
    window.open(path, "_blank");
  };

  return (
    <Menu.Item
      color="green"
      leftSection={<IconEye style={{ width: rem(14), height: rem(14) }} />}
      onClick={() => openInNewTab()}
      disabled={disabled}
    >
      <Translate>{title}</Translate>
    </Menu.Item>
  );
};

export default SettingsReadBtn;
