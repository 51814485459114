import React, { Fragment } from "react";

import { Divider, Paper, Space, Text } from "@mantine/core";
import {
  BADGE,
  CREATED_AT,
  FULL_NAME,
  LICENCE,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  ONLINE,
  PHONE,
  TAXI,
} from "Constant";

import SimpleTable from "ComponentsMantine/SimpleTable";
import ChangeBadge from "./ChangeBadge/ChangeBadge";

const ListOfData = ({ driverDetails, client_code }) => {
  const getFullName = () =>
    driverDetails.full_name ??
    `${driverDetails.user_civility}. ${driverDetails.user_firstname} ${driverDetails.user_lastname}`;

  const driverPersonelData = [
    {
      title: FULL_NAME,
      key: getFullName(),
    },
    {
      title: BADGE,
      fn: true,
      key: (
        <ChangeBadge
          data={
            driverDetails?.badge_serial_number ||
            driverDetails?.badge_serial ||
            driverDetails?.badge
          }
          driverCode={driverDetails.code_user}
          client_code={client_code}
        />
      ),
    },

    {
      title: "Email",
      key: driverDetails.user_mail,
    },
    {
      title: PHONE,
      key: driverDetails.user_phone,
    },
    {
      title: TAXI,
      key: driverDetails.driver_taxi,
    },
    {
      title: CREATED_AT,
      key: driverDetails.created_at,
    },
    {
      title: LICENCE,
      key: driverDetails.usr_licence,
    },
    {
      title: LICENCE_GOT_AT,
      key: driverDetails.usr_licence_got_at,
    },
    {
      title: LICENCE_EXPIRE_AT,
      key: driverDetails.usr_licence_expire_at,
    },
  ];

  return (
    <Fragment>
      <Paper shadow="xs" withBorder p="xl">
        <Divider
          my="xs"
          label={<Text fw={700}> General </Text>}
          labelPosition="center"
        />
        <SimpleTable requiredKeys={driverPersonelData} />
      </Paper>
      <Space h="md" />
      {/* <Paper shadow="xs" withBorder p="xl">
        <Divider
          my="xs"
          label={<Text fw={700}> {PROFESSIONAL_INFO} </Text>}
          labelPosition="center"
        />
        <SimpleTable requiredKeys={driverProfessionelData} />
      </Paper> */}
    </Fragment>
  );
};

export default ListOfData;
