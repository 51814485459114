export const getPurchaseRequests = (status) => {
  const statusColors = {
    CANCELED: "red",
    PENDING: "green",
    DRAFT: "indigo",
    FINISHED: "blue",
    // "En preparation": "yellow",

    // "En livraison": "orange",
    // Pret: "blue",
    // Clôturé: "cyan",
  };

  return statusColors[status] || "grape"; // Default color if status doesn't match
};

const STATUS_CODE_COLOR = {
  draft: "indigo",
  pending: "orange",
  canceled: "red",
  finished: "blue",
  under_examination: "yellow",
  active: "green",
  to_pickup: "cyan",
  picked_up: "lime",
  received: "teal",
  rejected: "red",
  activated: "green",
  in_stock: "blue",
  in_test: "purple",
  removed: "gray",
  in_repair: "orange",
  in_preparation: "yellow",
  in_primitive: "pink",
  in_transit: "cyan",
  repaired: "green",
  sold: "green",
  external: "brown",
  in_delivery: "teal",
  closed: "gray",
  ready: "blue",
  paid: "green",
  published: "lime",
  confirmed: "cyan",
  created: "purple",
  sent: "orange",
  delivred: "lime",
  opened: "yellow",
  returned: "red",
  declined_by_customer: "red",
  declined_by_taxi: "red",
  incident_customer: "orange",
  incident_taxi: "orange",
  sent_to_operator: "blue",
  received_by_operator: "teal",
  received_by_taxi: "lime",
  accepted_by_taxi: "green",
  accepted_by_customer: "green",
  customer_on_board: "cyan",
  timeout_taxi: "purple",
  timeout_customer: "purple",
  failure: "red",
  busy: "orange",
  free: "green",
  off: "gray",
  on: "lime",
};

const STATUS_LABEL_COLOR = {
  // ---------- ticket ----------
  "en cours": "blue",
  résolu: "green",
  fermé: "gray",
  planifié: "lime",

  // ---------- purchase ----------
  créé: "purple", // correspond à "created" => purple
  brouillon: "indigo", // draft => indigo
  clôturé: "gray", // closed => gray
  "en livraison": "teal", // in_delivery => teal
  prêt: "blue", // ready => blue
  "en préparation": "yellow", // in_preparation => yellow
  payé: "green", // paid => green
  confirmé: "cyan", // confirmed => cyan
  annulé: "red", // canceled => red

  // ---------- contract ----------
  expiré: "gray",
  publié: "lime",
  brouillon: "indigo",
  annulé: "red",

  // ---------- slip ----------
  annulé: "red",
  livré: "lime", // delivred => lime
  ouvert: "yellow", // opened => yellow
  retourné: "red", // returned => red
  expédié: "orange", // sent => orange

  // ---------- material ----------
  "en primitive": "pink", // in_primitive => pink
  rejeté: "red", // rejected => red
  prêt: "blue", // ready => blue
  vendu: "green", // sold => green
  "en transit": "cyan", // in_transit => cyan
  réparé: "green", // repaired => green
  externe: "brown", // external => brown
  activé: "green", // activated => green
  retiré: "gray", // retired / removed => gray
  "en stock": "blue", // in_stock => blue
  "en préparation": "yellow", // in_preparation => yellow
  "en test": "purple", // in_test => purple
  "en reparation": "orange", // in_repair => orange
  // (doublon "retiré" supprimé)

  // ---------- work_order ----------
  brouillon: "indigo",
  clôturé: "gray",
  prêt: "blue", // ready => blue
  "en préparation": "yellow", // in_preparation => yellow
  confirmé: "cyan", // confirmed => cyan
  annulé: "red",

  // ---------- delivery ----------
  "a enlever": "pink", // to_pickup => pink (choix arbitraire)
  annulé: "red",
  brouillon: "indigo",
  "en préparation": "yellow",
  "en livraison": "teal",
  reçu: "teal", // received => teal

  // ---------- booking ----------
  "client à bord": "cyan", // customer_on_board => cyan
  "accepté par client": "green", // accepted_by_customer => green
  "envoyé à l'opérateur": "blue", // sent_to_operator => blue
  "timeout accepted by customer": "purple",
  "taxi en route": "lime", // taxi_arriving => lime
  "reçu par taxi": "lime", // received_by_taxi => lime
  "reçu par l'opérateur": "teal", // received_by_operator => teal
  reçu: "teal", // received => teal
  achevé: "blue", // finished => blue
  // (doublon "timeout accepted by customer" supprimé)
  timeout_customer: "purple",
  "refus par client": "red", // declined_by_customer => red
  "timeout taxi": "purple", // timeout_taxi => purple
  "incident taxi": "orange", // incident_taxi => orange
  "refus par taxi": "red", // declined_by_taxi => red
  échec: "red", // failure => red
  "incident client": "orange", // incident_customer => orange
  "accepté par taxi": "green", // accepted_by_taxi => green

  // ---------- trip ----------
  rejeté: "red", // rejected => red
  refusé: "red", // refused => red
  annuler: "red", // canceled => red (libellé "annuler")
  "en cours": "blue", // in_progress => blue
  terminé: "blue", // finish => blue
  accepté: "green", // accepted => green
  "en attente": "orange", // waiting => orange
};

export const getStatusColor = (code) => {
  const smallString = code ? code.toLowerCase() : null;
  if (smallString)
    return (
      STATUS_CODE_COLOR[smallString] ||
      STATUS_LABEL_COLOR[smallString] ||
      "gray"
    );
  return "gray";
};
