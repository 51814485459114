export const allStatus = {
  ticket: ["En cours", "Résolu", "Fermé", "Planifié"],
  purchase: [
    "Crée",
    "Brouillon",
    "Clôturé",
    "En livraison",
    "Pret",
    "En preparation",
    "Payé",
    "Confirmé",
    "Annulé",
  ],
  contract: ["Expiré", "Publié", "Brouillon", "Annulé"],
  slip: ["Annulé", "Livré", "Ouvert", "Retourné", "Expedié"],
  material: [
    "En primitive",
    "Rejeté",
    "Prêt",
    "Vendu",
    "En Transit",
    "Réparé",
    "Externe",
    "Activé",
    "En Stock",
    "En préparation",
    "En test",
    "En reparation",
    "Retiré",
  ],
  work_order: [
    "Brouillon",
    "Clôturé",
    "Pret",
    "En preparation",
    "Confirmé",
    "Annulé",
  ],
  delivery: [
    "A enlever",
    "Annulé",
    "Brouillon",
    "En preparation",
    "En livraison",
    "Reçu",
  ],
  booking: [
    "Client à bord",
    "Accepté par client",
    "Envoyé à l'opérateur",
    "Taxi en route",
    "Reçu par taxi",
    "Reçu par l'opérateur",
    "Reçu",
    "Achevé",
    "Timeout accepted by customer",
    "Timeout_customer",
    "Refus par client",
    "Timeout taxi",
    "Incident taxi",
    "Refus par taxi",
    "échec",
    "Incident client",
    "Accepté par taxi",
  ],
  trip: [
    "Rejeté",
    "Refusé",
    "Annuler",
    "En cours",
    "Terminé",
    "Accepté",
    "En attente",
  ],
};
