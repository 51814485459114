import { Group } from "@mantine/core";

import { createdAt, updatedAt } from "Constant/objs";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

import { getCodeLabel } from "helper/getOptions";

import { PHONE, TAXIMETER } from "Constant";

import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";

// Urls
export const API = {
  list: "/back/sims",
  choice: "/back/sims/choices",
  Serial: "/back/sims/serials",
  create: "/back/sim",
  get: "/back/sim/",
  edit: "/back/sim/",
  patch: "/back/sim/",
  import: "/back/sims",
};

// Columns
export const COLUMNS = [
  // {
  //   name: <SelectAll>generate all</SelectAll>,
  //   cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
  //   button: "true",
  // },

  {
    name: "iccid",
    selector: "iccid",
    enableColumnFilter: true,
    enableSorting: true,
  },
  {
    name: PHONE,
    selector: "phone",
    enableColumnFilter: true,
    enableSorting: true,
  },
  {
    name: "puk",
    selector: "puk",
    enableColumnFilter: true,
    enableSorting: true,
  },

  {
    header: TAXIMETER,
    size: 300,
    accessorFn: (originalRow) => originalRow.taximeter,
    id: "taximeter",
    enableColumnFilter: true,
    enableSorting: true,

    Cell: ({ row }) => {
      const { taximeter } = row.original;
      const path = `/taximeter/${taximeter}`;

      return <ReadSheetBtn read={path} title={taximeter} disabled={true} />;
    },
  },

  {
    id: "actions",
    button: "true",
    Cell: ({ row }) => {
      const { code, active, serial_number } = row.original;
      return (
        <Group gap="xs">
          <EditBtn read={API.get + code} extra={serial_number} />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
  createdAt,
];

export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "iccid",
    placeholder: "iccid",
    name: "iccid",
    inputForm: "SimpleInput",
  },
  {
    title: "puk",
    placeholder: "puk",
    name: "puk",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "iccid",
    placeholder: "iccid",
    name: "iccid",
    inputForm: "SimpleInput",
  },
  {
    title: "puk",
    placeholder: "puk",
    name: "puk",
    inputForm: "SimpleInput",
  },
];
