import { Badge, Grid, Group } from "@mantine/core";

import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";

import {
  getCodeLabel,
  getModelsLabel,
  getServiceLabel,
} from "helper/getOptions";

import EditBtn from "./EditBtn";
import { CancelEstimate, ConfirmEstimate } from "./OptionsBtns";
import {
  CLIENT,
  PRICE_TTC,
  PRODUCTS,
  SERVICES,
  STATUS,
  TAXI,
  WORK_ORDER,
} from "Constant";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import DropDownBtns from "./DropDownBtns";

// Urls
export const API = {
  list: "/back/estimates",
  get: (p) => `/back/estimate/${p}`,
  create: "/back/estimate",
};

// Columns
export const COLUMNS = [
  {
    id: "number",
    name: "N° estimate",
    selector: "estimate_number",
    enableColumnFilter: true,
    enableSorting: false,
  },
  {
    accessorFn: (originalRow) => originalRow.work_order,
    id: "work_order",
    header: WORK_ORDER,
    size: 200,

    enableColumnFilter: false,
    enableSorting: false,

    Cell: ({ row }) => {
      const { work_order, work_order_code } = row.original;
      const path = `/work-order/${work_order_code}`;
      if (work_order_code)
        return <ReadSheetBtn read={path} title={work_order} />;
    },
  },

  {
    accessorFn: (originalRow) => originalRow.complete_name,
    id: "client",
    header: CLIENT,
    size: 300,

    Cell: ({ row }) => {
      const { complete_name, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={complete_name} />;
    },
    enableColumnFilter: true,
    enableSorting: false,
  },
  {
    header: TAXI,
    size: 300,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",
    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    name: PRICE_TTC,
    selector: "price_ttc",
    enableColumnFilter: false,
    enableSorting: true,
  },

  {
    name: "Creator",
    selector: "creator",
    enableColumnFilter: false,
    enableSorting: false,
  },
  { name: PRODUCTS, selector: "count_products", enableSorting: true },
  {
    name: SERVICES,
    selector: "count_services",
    enableSorting: true,
  },

  createdAt,
  updatedAt,
  statusObj("purchase"),

  {
    // header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      return <DropDownBtns data={row.original} />;

      // return (
      //   <Group gap="xs">
      //     <Grid>
      //       <Grid.Col span={4}>
      //         <ReadPageBtn read={`/estimate/${code}`} />
      //       </Grid.Col>
      //       <Grid.Col span={4}>
      //         <EditBtn read={`/edit-estimate/${code}`} disabled={!to_edit} />
      //       </Grid.Col>
      //       <Grid.Col span={4}>
      //         <CancelEstimate
      //           url={urlCancel}
      //           disabled={!to_cancel}
      //           row={row.original}
      //         />
      //       </Grid.Col>
      //     </Grid>

      //     <ConfirmEstimate url={urlConfirm} disabled={!to_confirm} />
      //   </Group>
      // );
    },
  },
];

export const DETAIL_ESTIMATE = [
  {
    title: "Client",
    name: "client",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/client",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Taxi",
        name: "taxi",
        optionsApi: `/back/client/${param}/taxis/choices`,
        getOptions: getCodeLabel,
      };
    },
  },
  {
    title: "Deposit",
    name: "deposit",
    inputForm: "NumberInput",
  },
  {
    title: "Time scale",
    name: "timescale",
    inputForm: "NumberInput",
  },
  {
    title: "Remaining amount",
    name: "remainingAmount",
    inputForm: "NumberInput",
  },
];

export const CATEGORY_MODEL = {
  title: "Category",
  name: "category",
  inputForm: "MultiSelectApiInput",
  optionsApi: "/back/inventory-categories",
  getOptions: getCodeLabel,
  secondSelect: (param) => {
    return {
      title: "Model",
      name: "model",
      optionsApi: `/back/models/choices/${param}`,
      getOptions: getModelsLabel,
    };
  },
};

export const CREATE_SERVICE = [
  {
    title: "Service",
    name: "service",
    inputForm: "SelectApiInput",
    optionsApi: "/back/services",
    getOptions: getServiceLabel,
  },

  {
    title: "Date begin",
    name: "dateBegin",
    inputForm: "DateInput",
  },
  {
    title: "Date end",
    name: "dateEnd",
    inputForm: "DateInput",
  },

  {
    title: "Quantity",
    name: "quantity",
    inputForm: "NumberInput",
  },
  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
  },
];

export const EDIT_ITEMS = [];

export const PRODUCT_STATIC_INPUT = [
  { title: "price HT", name: "price_ht" },
  { title: "VAT (%)", name: "vat" },
  { title: "price TTC", name: "price_ttc" },
];

export const ESTIMATE_STATIC_INPUT = [
  { title: "vat amount", name: "vatAmount" },
  { title: "price", name: "price" },
  { title: "price vat", name: "priceVat" },
];

// Form
export const CREATE_PRODUCT = [
  CATEGORY_MODEL,
  {
    title: "Quantity",
    name: "quantity",
    inputForm: "NumberInput",
  },

  {
    title: "Discount",
    name: "discount",
    inputForm: "NumberInput",
  },

  {
    title: "Description",
    placeholder: "Description",
    name: "description",
    inputForm: "TextareaInput",
  },
];
