export const formatDate = (dateString) => {
  if (!dateString) return dateString; // Ensure dateString is valid

  const date = new Date(dateString);

  if (isNaN(date.getTime())) return dateString; // Check for invalid date

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};
