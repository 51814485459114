import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import { API, COLUMNS } from "./data";

import { MY_PAYMENT_NOTIFICATION } from "Constant";
const PaymentNotification = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_PAYMENT_NOTIFICATION];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables
            newColumns={COLUMNS}
            link={list}
            columnSearch={false}
            createBtn={false}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default PaymentNotification;
