import { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";

import { API, CREATE_ITEMS, EDIT_ITEMS, COLUMNS } from "./data/parameter";

import { CREATE_PARAMETER, EDIT_PARAMETER } from "Constant";

const FlatfeeTable = ({ profileCode }) => {
  const list = API.list(profileCode);

  return (
    <Fragment>
      <DataTables newColumns={COLUMNS} link={list} />

      <ModalApp type="create" title={CREATE_PARAMETER}>
        <AddItem addURL={API.create(profileCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_PARAMETER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default FlatfeeTable;
