import React, { Fragment, useState } from "react";
import { Center, rem, SegmentedControl } from "@mantine/core";
import {
  Sunrise,
  Box,
  Award,
  Cast,
  PenTool,
  ShieldOff,
  HardDrive,
  AlertCircle,
  Printer,
  Zap,
  Monitor,
  ToggleLeft,
  Filter,
  Trello,
  PhoneCall,
} from "react-feather";

import Translate from "Components/Translate";

import Taximeter from "./Taximeter";
import Badge from "./Badge";
import Sensor from "./Sensor";
import Luminous from "./Luminous";
import PrinterComp from "./Printer";
import Stylus from "./Stylus";
import Bracket from "./Bracket";
import Sunshield from "./Sunshield";
import ObdAdapter from "./ObdAdapter";
import SosButton from "./SosButton";
import RelaySwitches from "./RelaySwitches";
import LightRelay from "./LightRelay";
import PulseFilter from "./PulseFilter";
import SIMcomp from "./SIMcomp";

import {
  MY_BADGES,
  MY_BOXES,
  MY_BRACKETS,
  MY_LIGTH_RELAY,
  MY_LUMINOUS,
  MY_OBD_ADAPTERS,
  MY_PRINTERS,
  MY_PULSE_FILTER,
  MY_RELAY_SWITCHES,
  MY_SENSOR,
  MY_SOS_BUTTONS,
  MY_STYLUS,
  MY_SUNSHIELDS,
  MY_TAXIMETERS,
  SIMS,
} from "Constant";
import { Card, CardBody } from "reactstrap";
import Boxes from "./Boxes";

const Main = () => {
  const [state, setState] = useState(0);

  const equipmentsStock = [
    { title: MY_TAXIMETERS, elem: <Taximeter />, icon: Monitor },
    { title: MY_BADGES, elem: <Badge />, icon: Award },
    { title: MY_SENSOR, elem: <Sensor />, icon: Sunrise },
    { title: MY_LUMINOUS, elem: <Luminous />, icon: Cast },
    { title: MY_PRINTERS, elem: <PrinterComp />, icon: Printer },
    { title: MY_BRACKETS, elem: <Bracket />, icon: Printer },
    { title: MY_BOXES, elem: <Boxes />, icon: Box },
    { title: MY_STYLUS, elem: <Stylus />, icon: PenTool },
    { title: MY_SUNSHIELDS, elem: <Sunshield />, icon: ShieldOff },
    { title: MY_OBD_ADAPTERS, elem: <ObdAdapter />, icon: HardDrive },
    { title: MY_SOS_BUTTONS, elem: <SosButton />, icon: AlertCircle },
    { title: MY_RELAY_SWITCHES, elem: <RelaySwitches />, icon: ToggleLeft },
    { title: MY_LIGTH_RELAY, elem: <LightRelay />, icon: Trello },
    { title: MY_PULSE_FILTER, elem: <PulseFilter />, icon: Filter },
    { title: SIMS, elem: <SIMcomp />, icon: PhoneCall },
  ];

  const menuEquipmentsStock = equipmentsStock.map((equipment, i) => ({
    value: i.toString(),
    label: (
      <Center style={{ gap: "10px" }}>
        <equipment.icon style={{ width: rem(16), height: rem(16) }} />
        <Translate>{equipment.title}</Translate>
      </Center>
    ),
  }));
  // const breadCrumbsProps = [MY_FINISHED_PRODUCTS_STOCKS];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          {/* <Breadcrumbs breadCrumbsProps={breadCrumbsProps} /> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "10px",
              padding: "10px",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <SegmentedControl
              // size="lg"
              // color="orange"
              data={menuEquipmentsStock}
              orientation="horizontal"
              fullWidth
              value={state.toString()}
              onChange={(value) => setState(parseInt(value))}
              style={{
                display: "flex",
                flexWrap: "wrap", // Wrap items to a new row if needed
                justifyContent: "center", // Center items in the row
                gap: "10px", // Space between items
                padding: "10px",
                width: "100%",
              }}
            />
          </div>
        </CardBody>
      </Card>

      {equipmentsStock[state].elem}
    </Fragment>
  );
};

export default Main;
