import { ActionIcon, Badge, Group, Menu, Tooltip } from "@mantine/core";

import {
  CLIENT,
  CREATED_AT,
  CREATOR,
  PRICE_TTC,
  PRODUCTS,
  SERVICES,
  SETTINGS,
  STATUS,
  TAXI,
} from "Constant";

import { getCodeLabel } from "helper/getOptions";

import {
  ConfirmBtn,
  ConfirmPaymentBtn,
  CreateDelivery,
  CreateDeliveryFormBtn,
  PrepareDeliveryFormBtn,
  ReadyDeliveryFormBtn,
} from "./PurchaseOrderBtns";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import { createdAt, statusObj, updatedAt } from "Constant/objs";

import DropDownBtns from "./DropDownBtns";
import Translate from "Components/Translate";
import { IconDots } from "@tabler/icons-react";
import SettingsReadBtn from "Components/MantineDataTableBtns/SettingsReadBtn";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";

// API
export const API = {
  list: "/back/purchaseorders",
};

// Columns
export const COLUMNS = [
  {
    name: "N° purchase order",
    selector: "po_number",
    enableColumnFilter: true,
  },

  {
    accessorFn: (originalRow) => originalRow.estimate_number,
    id: "estimate_number",
    header: "N° estimate",
    enableColumnFilter: true,

    Cell: ({ row }) => {
      const { estimate_number, estimate_code } = row.original;
      const path = `/estimate/${estimate_code}`;
      if (estimate_code)
        return <ReadSheetBtn read={path} title={estimate_number} />;
    },
  },

  // {
  //   accessorFn: (originalRow) => originalRow.status,
  //   id: "status",
  //   header: STATUS,

  //   filterFn: "equals",
  //   filterVariant: "select",
  //   mantineFilterSelectProps: {
  //     data: [
  //       { value: "canceled", label: "Annulé" },
  //       { value: "draft", label: "Brouillon" },
  //       { value: "confirmed", label: "Confirmé" },
  //       { value: "paid", label: "Payé" },
  //       { value: "created", label: "Crée" },
  //       { value: "ready", label: "Prêt" },
  //     ],
  //   },

  //   Cell: ({ row }) => {
  //     const { status, status_code } = row.original;

  //     return <StatusColumn code={status_code} label={status} />;
  //   },
  // },

  statusObj("purchase"),

  { name: PRODUCTS, selector: "count_products", enableSorting: true },
  { name: SERVICES, selector: "count_services", enableSorting: true },

  {
    name: PRICE_TTC,
    selector: "price_ttc",
    enableSorting: true,
  },
  {
    header: CLIENT,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",

    size: 300,

    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",

    size: 300,

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },
  {
    name: CREATOR,
    selector: "creator",
  },
  createdAt,
  updatedAt,

  {
    // header: "Actions",
    id: "actions",
    button: "true",
    size: 60,
    Cell: ({ row }) => {
      const { code, to_confirm, to_prepare, to_deliver, to_ready } =
        row.original;

      const confirmPaymentUrl = `/back/purchaseorder/${code}/confirm`;
      const createDeliveryFormUrl = `/back/purchaseorder/${code}/deliveryform`;
      const urlPrepare = `/back/purchaseorder/${code}/prepare`;
      const urlReady = `/back/purchaseorder/${code}/ready`;

      return (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate>{SETTINGS}</Translate>}>
              <ActionIcon size="lg" variant="subtle" color="gray">
                <IconDots color="gray" />
              </ActionIcon>
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            <SettingsReadBtn read={`/purchase-order/${code}`} />
            <CreateDeliveryFormBtn
              url={createDeliveryFormUrl}
              disabled={!to_deliver}
              data={row.original}
            />
            <ConfirmPaymentBtn url={confirmPaymentUrl} disabled={!to_confirm} />
            <ReadyDeliveryFormBtn url={urlReady} disabled={!to_ready} />
            <PrepareDeliveryFormBtn url={urlPrepare} disabled={!to_prepare} />
          </Menu.Dropdown>
        </Menu>
      );
    },
  },
];
