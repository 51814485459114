import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { activeObj, createdAt, statusObj, updatedAt } from "Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { ADDRESS, MODEL, STATUS } from "Constant";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";

// Urls
export const API = {
  list: "/back/printers",
  choice: "/back/printers/choices",
  Serial: "/back/printers/serials",
  create: "/back/printer",
  get: "/back/printer/",
  edit: "/back/printer/",
  patch: "/back/printer/",
  import: "/back/printers",
};

// Columns
export const COLUMNS = [
  statusObj("material"),
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: MODEL,
    selector: "model",
    id: "model",
  },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, active, serial_number, editable } = row.original;
      return (
        <Group gap="xs">
          <EditBtn
            read={API.get + code}
            extra={serial_number}
            disabled={!editable}
          />
          <ActiveBtn patch={API.patch + code} state={active} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  activeObj,
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: MODEL,
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/printer",
    getOptions: getTitleCodeLabel,
  },
  {
    title: ADDRESS,
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: MODEL,
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/printer",
    getOptions: getTitleCodeLabel,
  },
  {
    title: ADDRESS,
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
];
