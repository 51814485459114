import { LABEL } from "Constant";

// Urls
export const API = {
  list: (p) => `/back/profile/${p}/tariffs`,
  get: (p) => `/back/tariff/${p}`,
  create: (p) => `/back/profile/${p}/tariff`,
  edit: (p) => `/back/tariff/${p}`,
};

// Columns
export const COLUMNS = [
  {
    name: LABEL,
    selector: "label",
  },
  {
    name: "Owner share",
    selector: "owner_share",
  },
  {
    name: "Led",
    selector: "led",
  },
  { name: "Date begin", selector: "date_begin" },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Owner share",
    placeholder: "Owner share",
    name: "owner_share",
    inputForm: "NumberInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Led",
    placeholder: "led",
    name: "led",
    inputForm: "SimpleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Owner share",
    placeholder: "Owner share",
    name: "owner_share",
    inputForm: "NumberInput",
  },
  {
    title: "Label",
    placeholder: "Label",
    name: "label",
    inputForm: "SimpleInput",
  },
  {
    title: "Led",
    placeholder: "led",
    name: "led",
    inputForm: "SimpleInput",
  },
];
