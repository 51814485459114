import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import Breadcrumbs from "Components/Breadcrumbs";
import ImportExcel from "Components/ImportExcel";
import AddItem from "Components/AddItemWithZod";
import ModalApp from "Components/ModalApp";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data/SIM";

import { CREATE_SIM, EDIT_SIM, MY_SIMS } from "Constant";

const SIMcomp = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_SIMS];

  const handleImport = (data) => `{"data":${data}}`;

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} importBtn={true} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_SIM}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_SIM}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="import">
        <ImportExcel
          importApi={API.import}
          file_name="SIMS"
          handleImport={handleImport}
        />
      </ModalApp>
    </Fragment>
  );
};

export default SIMcomp;
