import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { fetchGetOptions } from "./redux/findByFilters";

const InitApp = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log("fetchGetOptions");

    dispatch(fetchGetOptions());
  }, [dispatch, location.pathname]);

  return null;
};

export default InitApp;
