import React from "react";
import { changeModalType } from "../../redux/modal";
import { useDispatch } from "react-redux";
import { GENERATE_QR_CODE, IMPORT } from "Constant";
import { IconFileTypeXls, IconQrcode } from "@tabler/icons-react";
import Translate from "Components/Translate";
import { Button } from "@mantine/core";

export const ImportBtn = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };

  return (
    <Button
      leftSection={<IconFileTypeXls size={14} />}
      variant="filled"
      color="gray"
      onClick={() => changeState("import")}
    >
      <Translate>{IMPORT}</Translate>
    </Button>
  );
};

export const SerialBtn = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };

  return (
    <Button
      leftSection={<IconQrcode size={14} />}
      variant="filled"
      color="cyan"
      onClick={() => changeState("QRcode")}
    >
      <Translate>{GENERATE_QR_CODE}</Translate>
    </Button>
  );
};

// onClick: () => changeState("import")
