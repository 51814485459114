import { Fragment, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { HiHomeModern } from "react-icons/hi2";
import { AiOutlineIdcard } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

import DataTables from "Components/DataTables";
import Breadcrumbs from "Components/Breadcrumbs/SheetOf";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";
import EditItem from "Components/EditItem";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import HeaderTabs from "Components/Header/HeaderTabs";
import ActiveBtn from "Components/DataTableBtns/ActiveBtn";

import useFetchData from "hooks/useFetchDataWithDep";
import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data";
import { CREATE_PARAMETER } from "Constant";

import { useDispatch } from "react-redux";
import { changeModalType } from "../../redux/modal";

const ReadParameterPage = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };

  const { code } = useParams();
  const [openCreateFrom, setOpenCreateFrom] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [editParam, setEditParam] = useState(0);

  const list = API.list(code);
  const { data, isLoading, isError } = useFetchData(
    `/back/parameter/${code}`,
    refresh
  );
  let parameterData;

  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    parameterData = data.data.results.data;
  }

  const dynamicPart = parameterData ? parameterData.label : null;
  const breadCrumbsProps = [dynamicPart];
  const requiredKeys = [
    {
      key: "label",
      name: "Label",
      icon: <HiHomeModern />,
    },
    {
      key: "defaultValue",
      name: "Default value",
      icon: <HiHomeModern />,
    },
    {
      key: "active",
      name: "Active",
      icon: <AiOutlineIdcard />,
      btn: <ActiveBtn patch={API.patch(code)} state={parameterData.active} />,
    },
    {
      key: "createdAt",
      name: "Created at",
      icon: <HiHomeModern />,
    },
  ];

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="Add new value"
          icon="pi pi-plus"
          severity="success"
          onClick={() => changeState("create")}
          raised
        />

        <Button
          label="Edit"
          icon="pi pi-pencil"
          severity="info"
          onClick={() => changeState("edit")}
          disabled={true}
        />

        <Button
          label="Refresh"
          icon="pi pi-sync"
          severity="secondary"
          raised
          onClick={() => setRefresh((prev) => prev + 1)}
        />
      </div>
    );
  };

  return (
    <Fragment>
      <Fragment>
        <Card className="mb-0">
          <CardBody>
            <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

            <Toolbar
              className="mb-4 bg-white text-black border border-gray-300"
              start={leftToolbarTemplate}
            />

            <Row style={{ display: "flex" }}>
              <Col md="3">
                {!isLoading && (
                  <HeaderTabs
                    title={dynamicPart}
                    objectData={parameterData}
                    requiredKeys={requiredKeys}
                  />
                )}
              </Col>
              <Col md="9">
                <DataTables newColumns={COLUMNS} link={list} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Fragment>

      <ModalApp type="create" title={CREATE_PARAMETER}>
        <AddItem addURL={API.create(code)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit">
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ReadParameterPage;
