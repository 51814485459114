import { Badge, Group } from "@mantine/core";

import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";

import { fullName } from "helper/fullName";
import { getCodeLabel } from "helper/getOptions";

import { ACTIVE, CATEGORY, MODEL, QUANTITY, STATUS } from "Constant";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import CancelBtn from "Components/DataTableBtns/CancelBtn";
import DeleteBtn from "Components/MantineDataTableBtns/DeleteBtn";

// API
export const API = {
  list: "/back/purchaserequests",
  create: "/back/rawmaterialpr",
  delete: "/back/rawmaterialpr/",
  finish: (code) => `/back/rawmaterialpr/${code}/finish`,
  validate: (code) => `/back/rawmaterialpr/${code}/validate`,
};

// Columns
export const COLUMNS = [
  { name: "N°", selector: "number" },
  { name: CATEGORY, selector: "category" },
  { name: QUANTITY, selector: "quantity" },
  { name: MODEL, selector: "model" },
  createdAt,
  updatedAt,
  statusObj("purchase"),

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { to_delete, code } = row.original;
      return (
        <Group gap="xs">
          <DeleteBtn
            delete={`/back/rawmaterialpr/${code}`}
            isDisable={!to_delete}
          />
        </Group>
      );
    },
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Quantity",
    name: "quantity",
    inputForm: "NumberInput",
  },
  {
    title: "Supplier",
    name: "Supplier",
    inputForm: "SimpleInput",
  },
  {
    title: "Category",
    name: "category",
    inputForm: "MultiSelectApiInput",
    optionsApi: "/back/rawmaterial/categories",
    getOptions: getCodeLabel,
    secondSelect: (param) => {
      return {
        title: "Model",
        name: "model",
        optionsApi: `/back/rawmaterial/${param}/models`,
        getOptions: getCodeLabel,
      };
    },
  },
];

export const EDIT_ITEMS = [];
