import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import Translate from "Components/Translate";

import { Badge } from "@mantine/core";

import { createdAt, updatedAt } from "Constant/objs";

import {
  CLIENT,
  CREATOR,
  PAID,
  PAYMENT_DATE,
  PRICE,
  PRODUCT,
  SERVICE,
  TAXI,
  TYPE,
  UPDATOR,
} from "Constant";

import { formatDate } from "helper/dateFormat";
import HandleResult from "helper/HandleResult";

import DepositProofModal from "./DepositProofModal";

export const getNextMonth = () => {
  try {
    const nextMonthDate = new Date();
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

    const year = nextMonthDate.getFullYear();
    const month = String(nextMonthDate.getMonth() + 1).padStart(2, "0");
    const day = String(nextMonthDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } catch {
    return "";
  }
};

// Urls
export const API = {
  list: `/back/schedules`,
  get: "/back/schedule/",
};

// Columns
export const COLUMNS = [
  {
    header: TAXI,
    size: 300,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",
    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  {
    header: CLIENT,
    size: 300,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",
    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },

  // {
  //   name: PRICE,
  //   selector: "price",
  //   filterFn: "betweenInclusive",
  // },

  {
    accessorKey: "price",
    header: PRICE,
    filterVariant: "range-slider",
    filterFn: "betweenInclusive",
    mantineFilterRangeSliderProps: {
      max: 1_000,
      min: 30,
      step: 1,
      label: (value) =>
        value.toLocaleString("en-TN", {
          style: "currency",
          currency: "TND",
        }),
    },
  },

  {
    header: PAID,
    id: "paid",
    button: "true",
    size: 30,
    Cell: ({ row }) => <DepositProofModal data={row.original} />,
    accessorFn: (originalRow) => originalRow.paid,
    enableSorting: true,
    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "true", label: "payé" },
        { value: "false", label: "non payé" },
      ],
    },
  },
  {
    accessorFn: (originalRow) => originalRow.product,
    id: "product",
    header: TYPE,
    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "true", label: "produit" },
        { value: "false", label: "service" },
      ],
    },

    Cell: ({ row }) => {
      const { product } = row.original;

      return (
        <Badge variant="default" color="indigo" size="lg">
          {product ? (
            <Translate>{PRODUCT}</Translate>
          ) : (
            <Translate>{SERVICE}</Translate>
          )}
        </Badge>
      );
    },
  },

  {
    accessorFn: (originalRow) => originalRow.payment_date,
    id: "payment_date",
    // filterVariant: "date",
    header: PAYMENT_DATE,
    Cell: ({ row }) => {
      const { payment_date } = row.original;
      return formatDate(payment_date);
    },
  },

  {
    name: CREATOR,
    selector: "creator",
  },
  {
    name: UPDATOR,
    selector: "updator",
  },
  createdAt,
  updatedAt,

  // {
  //   header: "Actions",
  //   id: "actions",
  //   button: "true",
  //   size: 120,
  //   Cell: ({ row }) => {
  //     const { code, to_confirm, to_cancel, to_edit } = row.original;
  //     const urlCancel = `/back/estimate/${code}/cancel`;
  //     const urlConfirm = `/back/estimate/${code}/confirm`;
  //     return <Group gap="xs"></Group>;
  //   },
  // },
];
