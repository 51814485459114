import { configureStore } from "@reduxjs/toolkit";

import toggleStyleReducer from "./toggleStyle";
import currentRowReducer from "./currentRow";
import searchLinkReducer from "./searchLink";
import getRowsReducer from "./rowsSlicer";
import modalReducer from "./modal";
import selectedRowsReducer from "./selectedRows";
import estimateSlicerReducer from "./estimateSlicer";
import chooseProdSlicer from "./chooseProdSlicer";
import requeteSqlSliceReducer from "./requeteSql";
import salesSlicerReducer from "./salesSlicer";
import formInputsReducer from "./formInputs";
import datasheetReducer from "./datasheetSlicer";
import findByFiltersReducer from "./findByFilters";

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    getRows: getRowsReducer,
    currentRow: currentRowReducer,
    searchLink: searchLinkReducer,
    toggleStyle: toggleStyleReducer,
    selectedRows: selectedRowsReducer,
    estimate: estimateSlicerReducer,
    chooseProd: chooseProdSlicer,
    requeteSql: requeteSqlSliceReducer,
    sales: salesSlicerReducer,
    formInputs: formInputsReducer,
    datasheet: datasheetReducer,
    findByFilters: findByFiltersReducer,
  },
});
