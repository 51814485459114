import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import logoSmarteo from "assets/images/logo/smarteo.jpeg";

import StaticTextTable from "Components/StaticData/StaticTextTable";
import PrintBtn from "Components/PrintBtn";

import {
  TOTAL,
  TOTAL_PRICE_TND,
  TOTAL_PRICE_WITH_VAT,
  TOTAL_VAT_AMOUNT_TND,
} from "Constant";

import { columnsProd, columnsServ, estimateDetailsColumns } from "./data";
import StaticTab from "./StaticTab";
import TimeScalComp from "./TimeScalComp";
import styles from "./estimate.module.css";

const BillSheet = ({ data, title }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    status,

    completeName,
    estimateNumber,
    taxi_info,
    createdAt,

    company,
    city,
    zipCode,
    address,
  } = data;

  console.log(data);
  const prixTtc = Number(data.priceVat) + Number(data.stamp);

  const totalData = [
    { label: "Prix HT", value: data.price },
    { label: "TVA", value: data.vatAmount },
    { label: "Timbre", value: data.stamp },
    { label: "Prix TTC", value: prixTtc },
    // { label: VAT_PERCENTAGE, value: data.vat },
  ];
  const showProd = data.products.length ? true : false;
  const showService = data.services.length ? true : false;
  const breadCrumbsProps = [`status: ${status}`];

  return (
    <Fragment>
      <Card>
        <CardBody>
          {/* <Breadcrumbs breadCrumbsProps={breadCrumbsProps} /> */}
          <div ref={componentRef}>
            <Row style={{ marginLeft: "50px", marginTop: "50px" }}>
              <Col className="col-7">
                <div style={{ marginLeft: "20px" }}>
                  <img src={logoSmarteo} className={styles["logo"]} alt="" />
                  <div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      {`${city} ${zipCode}`}
                    </div>
                    <div>{address}</div>
                    <div>{company}</div>
                  </div>
                </div>
              </Col>
              <Col className="col-5">
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    {title}
                  </div>
                  <div>{completeName}</div>
                  <div>{taxi_info}</div>
                  <div>{createdAt}</div>
                </div>
              </Col>
            </Row>

            <div>
              <div style={{ margin: "40px" }}>
                {showProd && (
                  <Fragment>
                    <div
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Produits
                    </div>
                    <StaticTab
                      newColumns={columnsProd}
                      newRows={data.products}
                    />
                  </Fragment>
                )}
                {showService && (
                  <Fragment>
                    <div style={{ fontSize: "16px", marginTop: "10px" }}>
                      Services
                    </div>

                    <StaticTab
                      newColumns={columnsServ}
                      newRows={data.services}
                    />
                  </Fragment>
                )}

                <div>
                  <div style={{ fontSize: "16px", marginTop: "10px" }}>
                    {/* Validité du devis 1 mois */}
                  </div>
                  <StaticTab
                    newColumns={estimateDetailsColumns}
                    newRows={[data]}
                  />
                  <TimeScalComp data={data} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StaticTextTable requiredData={totalData} title={TOTAL} />
            </div>
          </div>
          <div className={styles["print-btn"]}>
            <PrintBtn onClick={handlePrint} />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default BillSheet;
