const parsePrice = (price) => parseFloat(price) || 0;
const parseQantity = (price) => parseFloat(price) || 1;

export const getEstiamteCoefficient = (param) => {
  try {
    const data = localStorage.getItem("userData");

    // If the data is JSON, parse it
    const parsedData = JSON.parse(data);
    const { estimate_coefficient } = parsedData;

    let key = 0;

    if (param == 3) key = 3;
    else if (param > 3 && param <= 6) key = 6;
    else if (param > 6) key = 12;

    return estimate_coefficient[key] ?? 0;
  } catch (error) {
    console.error("An error occurred:", error);
    // Return 0 in case of any errors
    return 0;
  }
};

export const calculateProduct = (price_ht = 0, vat, quantity, discount = 0) => {
  // Ensure price_ht, vat, quantity, and discount are valid numbers
  const validPrice = isNaN(price_ht) ? 0 : parseFloat(price_ht);
  const validVat = isNaN(vat) ? 0 : parseFloat(vat);
  const validQuantity = isNaN(quantity) ? 1 : parseFloat(quantity);
  const validDiscount = isNaN(discount) ? 0 : parseFloat(discount);

  // Calculate the price after discount
  const priceAfterDiscount = validPrice - validPrice * (validDiscount / 100);

  // Calculate VAT and the total price with VAT (price TTC)
  const calculateVat = priceAfterDiscount * (validVat / 100);
  const priceVat = priceAfterDiscount + calculateVat;
  const priceTtc = priceVat * validQuantity;

  const roundedPriceTtc =
    priceTtc % 1 >= 0.5 ? Math.ceil(priceTtc) : Math.floor(priceTtc);
  const withoutRoundedPriceTtc = priceTtc.toFixed(2);

  return validVat ? roundedPriceTtc : withoutRoundedPriceTtc;
};

export const calculatePrice = (
  products,
  services,
  timescale,
  deposit = 0,
  estimate_coefficient
) => {
  console.log("estimate_coefficient :", estimate_coefficient);
  const estiamteCoefficient = estimate_coefficient / 100 + 1;

  const result = {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
  };

  const productPrice = {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
  };

  const servicePrice = {
    vatAmount: 0,
    price: 0,
    priceVat: 0,
  };

  products.forEach((prod) => {
    productPrice.price = parsePrice(prod.price) + productPrice.price;
    productPrice.priceVat = parsePrice(prod.price_ttc) + productPrice.priceVat;
  });

  services.forEach((serve) => {
    servicePrice.price = parsePrice(serve.price) + servicePrice.price;
    servicePrice.priceVat = parsePrice(serve.price_ttc) + servicePrice.priceVat;
  });

  const depositVal = parsePrice(deposit);
  result.price =
    (productPrice.price - depositVal) * estiamteCoefficient +
    servicePrice.price +
    depositVal;
  result.priceVat =
    (productPrice.priceVat - depositVal) * estiamteCoefficient +
    servicePrice.priceVat +
    depositVal;
  result.vatAmount = result.priceVat - result.price;

  return {
    vatAmount: result.vatAmount.toFixed(1),
    price: result.price.toFixed(1),
    priceVat: result.priceVat.toFixed(1),
  };
};
