import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import useFetchData from "../../hooks/useFetchData";

import { toggleSelectAll } from "../../redux/selectedRows";

import Loading from "../Loading/Loading";
import NotFound from "../NotFound";
import SVGImage from "./SVGImage";
import { getData } from "../../helper/getData";
import Translate from "Components/Translate";

const GenerateSerials = ({ serialURL }) => {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFF",
      padding: 30,
    },
    header: {
      fontSize: 24,
      textAlign: "center",
      marginBottom: 30,
    },
    itemsTable: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 60,
      margin: 20,
    },
    // ====
    tableRow: {
      margin: "auto",
      marginBottom: "10px",
      flexDirection: "row",
    },
    tableCell: {
      fontSize: 12,
      textAlign: "center",
      padding: 5,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(toggleSelectAll(false));
    };
  }, [serialURL]);

  const listOfcodes = useSelector((state) => state.selectedRows.listOfCodes);
  //const selectAll = useSelector((state) => state.selectedRows.selectAll);
  const selectAll = true;

  const codesQueryParam = `codes=["${listOfcodes.join('","')}"]`;
  const urlLink = selectAll ? serialURL : `${serialURL}?${codesQueryParam}`;

  const { data, isLoading, isError } = useFetchData(urlLink);

  if (!selectAll && listOfcodes.length === 0)
    return (
      <h1>
        <Translate>Please select one or more rows</Translate>
      </h1>
    );

  if (isLoading) return <Loading />;
  else {
    if (isError) return <NotFound />;
    const listOfSerials = getData(data);

    const MyPdfDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* <View style={styles.header}>
        <Text>Invoice</Text>
      </View> */}

          <View style={styles.itemsTable}>
            {listOfSerials.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <View
                  wrap={false}
                  style={{
                    display: "flex",
                    pageBreakInside: "avoid",
                    flexDirection: "col",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  {/* <Text style={styles.tableCell}>{item.model}</Text> */}
                  <Text style={styles.tableCell}>TAXIORA ®</Text>

                  <SVGImage qrString={item.serial_number} />
                  <Text style={styles.tableCell}>{item.serial_number}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
    return (
      <>
        <div>
          <PDFDownloadLink document={<MyPdfDocument />} fileName="somename.pdf">
            {({ _blob, _url, loading, _error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </div>
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <MyPdfDocument />
        </PDFViewer>
      </>
    );
  }
};

export default GenerateSerials;
