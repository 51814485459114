import React from "react";
import { Button } from "@mantine/core";
import { IconEye, IconXboxX } from "@tabler/icons-react";
import { isEmptyString } from "helper/getData";

const ReadSheetBtn = ({ read: path, title, disabled }) => {
  if (isEmptyString(title)) return <IconXboxX color="red" />;
  if (title)
    return (
      <Button
        disabled={disabled}
        variant="default"
        color="green"
        radius="xl"
        leftSection={<IconEye size={14} />}
        onClick={() => {
          window.open(path, "_blank");
        }}
      >
        {title}
      </Button>
    );
  return <IconXboxX color="red" />;
};

export default ReadSheetBtn;
