import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import {
  API,
  COLUMNS,
  CREATE_ITEMS,
  EDIT_ITEMS,
  SEARCH_ITEMS,
} from "./data/PulseFilter";

import {
  CREATE_PULSE_FILTER,
  EDIT_PULSE_FILTER,
  MY_PULSE_FILTER,
} from "Constant";
import GenerateSerials from "Components/GenerateSerials";

const PulseFilter = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_PULSE_FILTER];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} serialBtn={true} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_PULSE_FILTER}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_PULSE_FILTER}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="QRcode">
        <GenerateSerials serialURL={API.Serial} />
      </ModalApp>
    </Fragment>
  );
};

export default PulseFilter;
