import React, { useEffect, useState } from "react";
import { Menu, rem, Switch } from "@mantine/core";
import { IconToggleRight } from "@tabler/icons-react";

import errorMsg from "Services/errorMsg";
import axiosClient from "Services/axiosClient";

import { areYouSureAlert, changedAlert } from "Components/Alerts/Alerts";
import Translate from "Components/Translate";

import { ACTIVATE, DESACTIVATE } from "Constant";

const SettingsActiveBtn = ({ patch, state, extra, disabled }) => {
  const [isChecked, setIsChecked] = useState(state);

  useEffect(() => {
    setIsChecked(state);
  }, [state]);

  const changedWithSuccess = () => setIsChecked((prevState) => !prevState);

  const changeState = () => {
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        const newState = { active: !state };

        axiosClient
          .patch(patch, newState)
          .then((res) => {
            changedAlert();
            changedWithSuccess();
          })
          .catch((error) => {
            console.error("Request failed:", error);
            errorMsg(error);
          });
      }
    });
  };

  return (
    <Menu.Item
      leftSection={<Switch checked={isChecked} color="yellow" />}
      onClick={changeState}
      disabled={disabled}
    >
      <Translate>{isChecked ? DESACTIVATE : ACTIVATE}</Translate>
    </Menu.Item>
  );
};

export default SettingsActiveBtn;
