import React, { Fragment } from "react";
import EstimateSheet from "Common/EstimateSheet";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useFetchData from "hooks/useFetchDataWithDep";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";
import { getData } from "helper/getData";
import Breadcrumbs from "Components/Breadcrumbs";
import { Card, CardBody } from "reactstrap";
import BillSheet from "./BillSheet";

const ReadBillPage = () => {
  const { code } = useParams();
  const { refresh } = useSelector((state) => state.searchLink);
  const url = `/back/bill/${code}`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  if (isLoading) return <Loading />;
  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    const billData = getData(data);

    // Updated title and data
    const title = `Facture n° ${billData.billNumber}`;
    const services = billData.services.map((elem) => ({
      ...elem,
      service_label: elem.service,
    }));

    const breadCrumbsProps = [
      `Facture n° ${billData.billNumber} | ${billData.status}`,
    ];
    return (
      <Fragment>
        <Card>
          <CardBody>
            <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
            <BillSheet
              data={{
                ...billData,
                services: services,
              }}
              title={title}
            />
          </CardBody>
        </Card>
      </Fragment>
    );
  }
};

export default ReadBillPage;
