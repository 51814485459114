import React from "react";

import { Fragment } from "react";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";

import { API, CREATE_ITEMS, EDIT_ITEMS, COLUMNS } from "../data/tariff";

import { CREATE_TARIFF, EDIT_TARIFF } from "Constant";
import TariffDetails from "./TariffDetails";
import { Group } from "@mantine/core";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

const TariffTable = ({ profileCode }) => {
  const list = API.list(profileCode);

  const RenderDetailPanel = ({ data }) => {
    const { code } = data?.original;

    return <TariffDetails codeTarif={code} />;
  };

  const ACTIONS = {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 70,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;

      return (
        <Group gap="xs">
          <ActiveBtn patch={API.get(code)} state={active} />
          {/* <ReadPageBtn read={`/tariff/${code}`} /> */}
          <EditBtn read={API.get(code)} extra={label} />
        </Group>
      );
    },
  };

  return (
    <Fragment>
      <DataTables
        newColumns={[...COLUMNS, ACTIONS]}
        link={list}
        renderDetailPanel={({ row }) => <RenderDetailPanel data={row} />}
      />

      <ModalApp type="create" title={CREATE_TARIFF}>
        <AddItem addURL={API.create(profileCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_TARIFF}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default TariffTable;
