import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data/LightRelay";

import { CREATE_LIGTH_RELAY, EDIT_LIGTH_RELAY, MY_LIGTH_RELAY } from "Constant";
import GenerateSerials from "Components/GenerateSerials";

const LightRelay = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_LIGTH_RELAY];

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables newColumns={COLUMNS} link={list} serialBtn={true} />
        </CardBody>
      </Card>

      <ModalApp type="create" title={CREATE_LIGTH_RELAY}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_LIGTH_RELAY}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>

      <ModalApp type="QRcode">
        <GenerateSerials serialURL={API.Serial} />
      </ModalApp>
    </Fragment>
  );
};

export default LightRelay;
