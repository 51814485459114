import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { resetTitle, toggleModal } from "../../redux/modal";
import { useTranslation } from "react-i18next";

const ModalApp = (props) => {
  const { isOpen, modalType, modalTitle } = useSelector((state) => state.modal);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) dispatch(resetTitle());
  }, [isOpen]);

  const toggleModel = () => {
    dispatch(toggleModal());
  };

  const isEditExist = (inputString) => {
    return inputString.includes("edit") || inputString.includes("PDF");
  };

  const defaultTitle = props.title ? t(props.title) : props.title;
  const dynamicTitle = isEditExist ? modalTitle : "";
  const title = `${defaultTitle ?? ""} ${dynamicTitle ?? ""}`;

  return (
    <Modal
      isOpen={isOpen && modalType === props.type}
      toggle={toggleModel}
      size="xl"
      centered
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader toggle={toggleModel}>{title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {/* <ModalFooter>
        <Btn attrBtn={{ color: "secondary", onClick: toggleModel }}>Close</Btn>
        <Btn attrBtn={{ color: "success", onClick: toggleModel }}>Save</Btn>
      </ModalFooter> */}
    </Modal>
  );
};
export default ModalApp;
