import { Fragment } from "react";
import { Group } from "@mantine/core";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItemWithZod";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItemWithZod";

import { API, CREATE_ITEMS, EDIT_ITEMS } from "./data/flatfee";

import { CREATE_FLATFEE, EDIT_FLATFEE, LABEL, PRICE } from "Constant";

const FlatfeeTable = ({ profileCode }) => {
  const list = API.get(profileCode);

  const COLUMNS = [
    {
      name: LABEL,
      selector: "label",
    },
    {
      name: PRICE,
      selector: "price",
    },
    {
      header: "Actions",
      id: "actions",
      button: "true",
      size: 30,
      Cell: ({ row }) => {
        const { code, active, label } = row.original;

        return (
          <Group gap="xs">
            <ActiveBtn patch={API.patch(code)} state={active} />
            <EditBtn
              read={API.patch(code)}
              edit={`/back/profile/${profileCode}/flatfee/${code}`}
              extra={label}
            />
          </Group>
        );
      },
    },
  ];

  return (
    <Fragment>
      <DataTables newColumns={COLUMNS} link={list} />

      <ModalApp type="create" title={CREATE_FLATFEE}>
        <AddItem addURL={API.create(profileCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="edit" title={EDIT_FLATFEE}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default FlatfeeTable;
