import { Fragment, useState } from "react";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { SegmentedControl } from "@mantine/core";

import { Card, CardBody } from "reactstrap";

import { RULES, VALUES } from "Constant";

import RulesTable from "./RulesTable";
import ValuesTable from "./ValuesTable";

const TariffDetails = ({ codeTarif }) => {
  const { code: paramCode } = useParams();
  const code = codeTarif ?? paramCode;

  const [state, setState] = useState("rules");

  const componants = {
    rules: <RulesTable triffCode={code} />,
    values: <ValuesTable triffCode={code} />,
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <SegmentedControl
            transitionDuration={200}
            transitionTimingFunction="linear"
            color="yellow"
            fullWidth
            size="md"
            value={state}
            onChange={setState}
            data={[
              { label: RULES, value: "rules" },
              { label: VALUES, value: "values" },
            ]}
          />
          <Divider my="md" />

          {componants[state]}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TariffDetails;
