export const DEFAULT_SORTING = [{ id: "created_at", desc: true }];
export const DEFAULT_PAGINATION = { pageIndex: -1, pageSize: 1 };
export const DEFAULT_COLUMN_FILTERS = [{ id: "$locale", desc: "fr" }];
export const DEFAULT_COLUMN_PINNING = {
  left: ["actions", "status"],
  // right: ["status"],
};
export const DEFAULT_IMPORT_BTN = false;
export const DEFAULT_SERIAL_BTN = false;
export const DATE_FILTER = ["created_at", "updated_at"];

const formatDateTime = (date) => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if (year && month && day) return `${year}-${month}-${day}  00:00:00`;
  else return "";
};

export const appendDate = (params, filter) => {
  try {
    const { value } = filter;

    if (value[0]) params.append("begin_at", formatDateTime(value[0]));
    if (value[1]) params.append("end_at", formatDateTime(value[1]));
  } catch {
    params.append("begin_at", "");
    params.append("end_at", "");
  }
};

export const PAGINATION_CHOICES = () => [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
];

export const PAGINATION_CHOICES_FN = (total) => {
  // Create the final option object with total
  const finalOption = { value: String(total), label: `Total ${String(total)}` };

  const baseOptions = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "25", label: "25" },
    { value: "30", label: "30" },
    { value: "50", label: "50" },
  ];

  const isTotalIncluded = baseOptions.some(
    (option) => option.value === finalOption.value
  );

  return isTotalIncluded ? baseOptions : [...baseOptions, finalOption];
};

export const getRows = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return { total: 1, rows: [] };
  }
};

export const getRowsWithouPaggination = (data) => {
  try {
    return { total: 1, rows: data.data.results.data };
  } catch {
    return { total: 1, rows: [] };
  }
};
