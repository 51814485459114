import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SHEET_OF } from "../../Constant";
import Translate from "../Translate";
import { Helmet } from "react-helmet";

const Breadcrumbs = ({ breadCrumbsProps }) => {
  const { t } = useTranslation();
  const title = t(breadCrumbsProps[0]);
  const elements = breadCrumbsProps.slice(1);
  console.log(breadCrumbsProps);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="6">
              <div style={{ display: "flex", gap: "1em" }}>
                <h3>{t(SHEET_OF)}</h3>
                <h4> {title} </h4>
              </div>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb">
                {elements.map((elem, i) => (
                  <li className="breadcrumb-item" key={i}>
                    <Translate>{elem}</Translate>
                  </li>
                ))}
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
