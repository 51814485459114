import React from "react";
import { RequireAuth } from "react-auth-kit";
import {
  Route,
  Routes as Switch,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import AppLayout from "../Layout/Layout";

import LoginPage from "pages/LoginPage/LoginPage";
import NotFoundPage from "pages/NotFoundPage";
import EditProfile from "pages/EditProfile";
import HamePage from "pages/HomePage";
import Tests from "pages/Tests";

import ProtectedRoute from "./ProtectedRoute";
import PAGE_ROUTES from "./pages.routes";
import SideBar from "../Components/SideBarDataB";
import InitApp from "InitApp";

export default function Routes() {
  return (
    <Router>
      <Switch>
        {PAGE_ROUTES.map((onePage, index) => (
          <Route
            key={index}
            path={onePage.path}
            element={
              <RequireAuth loginPath="/login">
                <ProtectedRoute allowedRoles={onePage.roles}>
                  <AppLayout>
                    {onePage.name === "true" ? <SideBar /> : null}
                    {onePage.element}
                  </AppLayout>
                </ProtectedRoute>
              </RequireAuth>
            }
          />
        ))}

        <Route key="NotFound" path="/not-found" element={<NotFoundPage />} />

        <Route key="LoginPage" path="/login" element={<LoginPage />} />
        <Route
          key="LoginPage"
          path="/home"
          element={
            <AppLayout>
              <HamePage />
            </AppLayout>
          }
        />

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route key="LoginPage" path="/tests" element={<Tests />} />
        <Route
          key="MyProfile"
          path="/my-profile"
          element={
            <AppLayout>
              <EditProfile />
            </AppLayout>
          }
        />
      </Switch>
      <InitApp />
    </Router>
  );
}
