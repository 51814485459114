import { useState, Fragment } from "react";
import { Button } from "primereact/button";

import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { toggleRefresh } from "../../redux/searchLink";
import { toggleModal } from "../../redux/modal";
import { useDispatch } from "react-redux";

import CancelModalBtn from "Components/ModalApp/CancelModalBtn";
import FilterInput from "Components/InputsWithZod";
import postData from "./postData";

const AddItem = ({ addURL, items, defVal, customFn, cancelBtn }) => {
  const defInp = defVal ? defVal : {};
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const schema = getSchema(items);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const addedWithSuccess = () => {
    dispatch(toggleRefresh());
    dispatch(toggleModal());
  };

  const submitData = (data) => {
    setIsLoading(true);
    if (customFn) {
      customFn(data, addURL, setIsLoading, addedWithSuccess);
    } else postData(data, addURL, setIsLoading, addedWithSuccess);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(submitData)}>
        <div className="flex flex-wrap">
          {items.map((elem, i) => (
            <Fragment key={i}>
              <FilterInput
                control={control}
                item={elem}
                handleChange={register}
                errors={errors}
                oldValue={defVal ? defVal[elem.name] : null}
              />
            </Fragment>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1%",
            padding: "5px",
          }}
        >
          {cancelBtn ? cancelBtn : <CancelModalBtn />}

          <Button
            type="submit"
            label="Go"
            severity="success"
            loading={isLoading}
            onClick={handleSubmit(submitData)}
          />
        </div>
      </form>
    </Fragment>
  );
};

export default AddItem;

const getSchema = (param) => {
  const result = {};
  const defaultSchema = z.string().min(1, "Required");

  param.forEach(({ inputForm, required, schema, name }) => {
    if (inputForm === "CheckBox") {
      result[name] = z.boolean();
    }
    if (inputForm === "NumberInput") {
      result[name] = z.any();
    } else if (inputForm !== "CustomComponant") {
      result[name] =
        schema ||
        (required === undefined ? defaultSchema : z.string().optional());
    }
  });

  return z.object(result);
};
