import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Tooltip, Menu, rem, ActionIcon, Button } from "@mantine/core";

import {
  IconEdit,
  IconDots,
  IconAdjustments,
  IconEye,
  IconCreativeCommons,
  IconIdBadge2,
  IconCirclePlus,
  IconDownload,
  IconDeviceMobileMessage,
} from "@tabler/icons-react";

import {
  CREATE_DRIVER,
  CREATE_TAXI,
  DOWNLOAD_CG,
  DOWNLOAD_CNI,
  DOWNLOAD_LICENCE,
  EDIT_CLIENT,
  EDIT_DRIVERS,
  EDIT_TAXI,
  GO_TO_CLIENT_SHEET,
  GO_TO_TAXI_SHEET,
  SEND_LOGIN_SMS,
  SETTINGS,
  UPLOAD_CLIENT_LICENCE,
  UPLOAD_CLIENT_REGISTRY,
  UPLOAD_TAXI_LICENCE,
  UPLOAD_TAXI_REGISTRY,
} from "Constant";

import Translate from "Components/Translate";
import ActiveBtn from "./ActiveBtn";

import { useDispatch } from "react-redux";
import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";
import { toggleRefresh } from "../../redux/searchLink";
import { downloadFile } from "helper/downloadFile";
import patchItem from "Services/patchItem";

export const ClientSettingsBtn = ({ data, toClientSheet }) => {
  const dispatch = useDispatch();

  const {
    civility,
    lastname,
    firstname,
    client_cni,
    code_client,
    client_active,
    client_licence,
    full_name,
  } = data;
  const fullName = full_name ?? `${civility}. ${firstname} ${lastname}`;

  const url = `/back/client/${code_client}`;
  const urlLicence = `/back/client/${code_client}/document/licence`;
  const urlCni = `/back/client/${code_client}/document/cni`;

  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editClient"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };

  const handleClickLicence = () => {
    dispatch(changeModalType("editClientLicence"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow({ getLink: urlLicence }));
  };

  const handleClickRegistry = () => {
    dispatch(changeModalType("editClientCni"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow({ getLink: urlCni }));
  };

  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate>{SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>
          <Menu.Dropdown>
            {toClientSheet && (
              <Menu.Item
                leftSection={
                  <IconEye style={{ width: rem(14), height: rem(14) }} />
                }
                onClick={() => {
                  const path = `/client/${code_client}`;
                  window.open(path, "_blank");
                }}
              >
                <Translate>{GO_TO_CLIENT_SHEET}</Translate>
              </Menu.Item>
            )}

            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              <Translate>{EDIT_CLIENT}</Translate>
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconCreativeCommons
                  style={{ width: rem(14), height: rem(14) }}
                />
              }
              onClick={() => handleClickLicence()}
            >
              <Translate>{UPLOAD_CLIENT_LICENCE}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconIdBadge2 style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleClickRegistry()}
            >
              <Translate>{UPLOAD_CLIENT_REGISTRY}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconDownload style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={!client_cni}
              onClick={() => downloadFile(client_cni)}
            >
              <Translate>{DOWNLOAD_CNI}</Translate>
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconDownload style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={!client_licence}
              onClick={() => downloadFile(client_licence)}
            >
              <Translate>{DOWNLOAD_LICENCE}</Translate>
            </Menu.Item>
            <Menu.Item
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={client_active} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const DriverSettingsBtn = ({ data }) => {
  const dispatch = useDispatch();

  const {
    user_civility,
    user_firstname,
    user_lastname,
    code_user,
    user_active,
    driver_taxi_code,
    usr_owner,
    full_name,
  } = data;

  const fullName =
    full_name ?? `${user_civility}. ${user_firstname} ${user_lastname}`;

  const url = `/back/user/${code_user}`;

  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editDriver"));
    dispatch(changeTitle(fullName));
    dispatch(changeCurrentRow(selectedRow));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };

  const sendSmsToDriver = () => {
    const smsUrl = `/back/user/${code_user}/identifier`;
    patchItem(smsUrl);
  };

  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              <Translate>{EDIT_DRIVERS}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconDeviceMobileMessage
                  style={{ width: rem(14), height: rem(14) }}
                />
              }
              onClick={sendSmsToDriver}
            >
              <Translate>{SEND_LOGIN_SMS}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconEye style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={!driver_taxi_code}
              onClick={() => {
                const path = `/taxi/${driver_taxi_code}`;
                window.open(path, "_blank");
              }}
            >
              <Translate>{GO_TO_TAXI_SHEET}</Translate>
            </Menu.Item>

            <Menu.Item
              disabled={usr_owner}
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={user_active} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const TaxisSettingsBtn = ({ data }) => {
  const dispatch = useDispatch();
  const { code } = useParams();

  const { taxi_serial_number, code_taxi, active_taxi, taxi_cg, taxi_licence } =
    data;
  const url = `/back/client/${code}/taxi/${code_taxi}`;
  const urlLicence = `/back/client/${code}/taxi/${code_taxi}/document/cg`;
  const urlRegistry = `/back/client/${code}/taxi/${code_taxi}/document/registry`;

  const selectedRow = {
    getLink: url,
    putLink: url,
  };

  const handleClick = () => {
    dispatch(changeModalType("editTaxi"));
    dispatch(changeTitle(taxi_serial_number));
    dispatch(changeCurrentRow(selectedRow));
  };

  const handleClickLicence = () => {
    dispatch(changeModalType("editTaxiLicence"));
    dispatch(changeTitle(taxi_serial_number));
    dispatch(changeCurrentRow({ getLink: urlLicence }));
  };

  const handleClickRegistry = () => {
    dispatch(changeModalType("editTaxiRegistry"));
    dispatch(changeTitle(taxi_serial_number));
    dispatch(changeCurrentRow({ getLink: urlRegistry }));
  };

  const refresh = () => {
    dispatch(toggleRefresh());
  };
  return (
    <Fragment>
      <ActionIcon size="lg" variant="subtle" color="gray">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate> {SETTINGS}</Translate>}>
              <IconDots color="gray" />
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              leftSection={
                <IconEye style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => {
                const path = `/taxi/${code_taxi}`;
                window.open(path, "_blank");
              }}
            >
              <Translate>{GO_TO_TAXI_SHEET}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconEdit style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={handleClick}
            >
              <Translate>{EDIT_TAXI}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconCreativeCommons
                  style={{ width: rem(14), height: rem(14) }}
                />
              }
              onClick={() => handleClickLicence()}
            >
              <Translate>{UPLOAD_TAXI_LICENCE}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconIdBadge2 style={{ width: rem(14), height: rem(14) }} />
              }
              onClick={() => handleClickRegistry()}
            >
              <Translate>{UPLOAD_TAXI_REGISTRY}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconDownload style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={!taxi_cg}
              onClick={() => downloadFile(taxi_cg)}
            >
              <Translate>{DOWNLOAD_CG}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconDownload style={{ width: rem(14), height: rem(14) }} />
              }
              disabled={!taxi_licence}
              onClick={() => downloadFile(taxi_licence)}
            >
              <Translate>{DOWNLOAD_LICENCE}</Translate>
            </Menu.Item>

            <Menu.Item
              leftSection={
                <IconAdjustments style={{ width: rem(14), height: rem(14) }} />
              }
            >
              <ActiveBtn state={active_taxi} patch={url} refresh={refresh} />
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </ActionIcon>
    </Fragment>
  );
};

export const CreateDriver = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };
  return (
    <Button
      color="teal"
      onClick={() => changeState("createDriver")}
      leftSection={<IconCirclePlus size={14} />}
    >
      <Translate>{CREATE_DRIVER}</Translate>
    </Button>
  );
};

export const CreateTaxi = () => {
  const dispatch = useDispatch();

  const changeState = (type) => {
    dispatch(changeModalType(type));
  };
  return (
    <Button
      color="teal"
      onClick={() => changeState("createTaxi")}
      leftSection={<IconCirclePlus size={14} />}
    >
      <Translate>{CREATE_TAXI}</Translate>
    </Button>
  );
};
