import { z } from "zod";
import { Badge } from "@mantine/core";

import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";

import { CREATED_AT, STATUS, TESTER, UPDATED_AT } from "Constant";

import { formatDate } from "helper/dateFormat";
import { getStatusColor } from "helper/getColor";
import { store } from "../redux/store";
import { allStatus } from "./status";
import moment from "moment";

const getDate = (date) => {
  try {
    const res = moment(date).format("YYYY-MM-DD");

    return res === "Invalid date" ? date : res;
  } catch {
    return date;
  }
};

export const activeObj = {
  name: "active",
  title: "Active",
  inputForm: "SelectInput",
  options: [
    {
      value: "",
      label: "all",
      name: "active",
    },
    {
      value: "true",
      label: "active",
      name: "active",
    },
    {
      value: "false",
      label: "not active",
      name: "active",
    },
  ],
};

export const civilityObj = {
  name: "civility",
  title: "Civility",
  inputForm: "SelectInput",
  schema: z.any({
    required_error: "Required",
    invalid_type_error: "Required",
  }),
  options: [
    {
      value: "M",
      label: "Mr",
      name: "civility",
    },
    {
      value: "MME",
      label: "Mrs",
      name: "civility",
    },
  ],
};

export const testerObj = {
  name: "tester",
  title: TESTER,
  inputForm: "SelectInput",
  schema: z.boolean({
    required_error: "Required",
    invalid_type_error: "Required",
  }),

  options: [
    {
      value: true,
      label: "Testeur",
      name: "tester",
    },
    {
      value: false,
      label: "Client",
      name: "tester",
    },
  ],
};

export const captureObj = {
  title: "Capture capture",
  name: "clientCapture",
  inputForm: "SelectInput",
  options: [
    {
      value: "FB",
      label: "Facebook",
      name: "capture",
    },
    {
      value: "Instagram",
      label: "Instagram",
      name: "capture",
    },
    {
      value: "Tiktok",
      label: "Tiktok",
      name: "capture",
    },
    {
      value: "Email",
      label: "Email",
      name: "capture",
    },
    {
      value: "Events",
      label: "Events",
      name: "capture",
    },
    {
      value: "Mouth to ear",
      label: "Mouth to ear",
      name: "capture",
    },
    {
      value: "Others",
      label: "Others",
      name: "capture",
    },
  ],
};

export const saleOrTry = {
  name: "type",
  title: "Type",
  inputForm: "SelectInput",
  options: [
    {
      value: "sale",
      label: "Sale",
    },
    {
      value: "try",
      label: "Try",
    },
  ],
};

export const createdAt = {
  id: "created_at",
  header: CREATED_AT,
  accessorFn: (originalRow) => getDate(originalRow.created_at),

  // filterVariant: "date-range",
  // filterVariant: "date",
  // enableSorting: true,
  // enableColumnFilter: true,
  // Cell: ({ row }) => {
  //   const { created_at } = row.original;
  //   return formatDate(created_at);
  // },
};

export const updatedAt = {
  id: "updated_at",
  header: UPDATED_AT,
  accessorFn: (originalRow) => getDate(originalRow.updated_at),

  // id: "updated_at",
  // filterVariant: "date-range",
  // enableSorting: true,
  // enableColumnFilter: true,
  // Cell: ({ row }) => {
  //   const { updated_at } = row.original;
  //   return formatDate(updated_at);
  // },
};

export const statusObj = (key) => {
  return {
    accessorFn: (originalRow) => originalRow.status,
    id: "status",
    header: STATUS,
    enableSorting: true,
    enableColumnFilter: true,

    filterVariant: "multi-select",
    mantineFilterSelectProps: {
      data: allStatus[key],
    },

    Cell: ({ row }) => {
      const { status, status_code, status_label } = row.original;
      return (
        <Badge color={getStatusColor(status_code ?? status)} size="lg">
          {status ?? status_code}
        </Badge>
      );
    },
  };
};

// export const statusObj = (key) => {
//   return {
//     accessorFn: (originalRow) => originalRow.status,
//     id: "status",
//     header: STATUS,
//     enableSorting: true,
//     enableColumnFilter: true,

//     Filter: ({ column, header, table }) => {
//       const url = `/back/statuses/${key}`;
//       return (
//         <FilterSelectBar
//           url={url}
//           column={column}
//           header={header}
//           table={table}
//         />
//       );
//     },

//     Cell: ({ row }) => {
//       const { status, status_code } = row.original;
//       return (
//         <Badge color={getStatusColor(status_code ?? status)} size="lg">
//           {status ?? status_code}
//         </Badge>
//       );
//     },
//   };
// };
