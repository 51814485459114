export const getCodeLabel = (data) => {
  const options = data.map((option) => ({
    value: option.code,
    label: option.label,
    name: option.label,
  }));
  return options;
};
export const getModelsLabel = (data) => {
  const options = data.flatMap((option) =>
    option.models.map((model) => ({
      value: model.code,
      label: model.label,
      name: model.label,
    }))
  );
  return options;
};

export const getOptionNumber = (from, to) => {
  const options = [];
  for (let i = from; i < to + 1; i++) {
    options.push({
      value: i,
      label: i,
    });
  }
  return options;
};

export const getTitleCodeLabel = (inputArray) => {
  try {
    return inputArray.map((group) => {
      return {
        label: group.label,
        options: group.models.map((model) => ({
          value: model.code,
          label: model.label,
        })),
      };
    });
  } catch {
    return [];
  }
};

export const getServiceLabel = (data) => {
  const services = data.rows;

  const options = services.map((option) => ({
    value: option.code,
    label: option.label,
    name: option.label,
  }));
  return options;
};

export const getCodeSerial = (param) => {
  try {
    return param.data.results.data.map((item) => {
      return {
        code: item.code,
        label: item.serial_number,
      };
    });
  } catch {
    return [];
  }
};

export const getCarModel = (param) => {
  return param.map((elem) => {
    return {
      label: elem.label,
      options: elem.models.map((option) => ({
        value: option.code,
        label: option.label,
      })),
    };
  });
};

export const getCarModelForNativeSelect = (param) => {
  return param.map((elem) => {
    return {
      group: elem.label,
      items: elem.models.map((option) => ({
        value: option.code,
        label: option.label,
      })),
    };
  });
};

export const getFullNameCode = (data) => {
  return data.map((elem) => ({
    value: elem.code,
    label: `${elem.firstname} ${elem.lastname}`,
  }));
};
