import { Card, CardBody } from "reactstrap";
import { Fragment } from "react";

import ExportRowsPdf from "Components/MantineDataTableBtns/ExportRowsPdf";
import DataTables from "Components/DataTables/DataTable";
import Breadcrumbs from "Components/Breadcrumbs";
import ModalApp from "Components/ModalApp";
import UploadDoc from "Components/UploadDoc";

import { MY_SCHEDULES } from "Constant";

import { API, COLUMNS, getNextMonth } from "./data";

const SchedulesPage = () => {
  const list = API.list;

  const breadCrumbsProps = [MY_SCHEDULES];

  const mantineTableBodyRowProps = ({ row }) => {
    const { color } = row?.original;
    if (color) {
      return {
        style: {
          backgroundColor: color,
        },
      };
    }
    return row;
  };

  const defColumnFilters = [
    {
      id: "endAt",
      value: getNextMonth(),
    },
  ];

  const defSorting = [
    { id: "created_at", desc: false },
    // { id: "paid", desc: false },
  ];

  const columnPinning = {
    left: ["paid"],
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />

          <DataTables
            newColumns={COLUMNS}
            link={list}
            createBtn={false}
            mantineTableBodyRowProps={mantineTableBodyRowProps}
            defColumnFilters={defColumnFilters}
            defSorting={defSorting}
            CustomComponent={ExportRowsPdf}
            columnPinning={columnPinning}
          />

          <ModalApp
            type="setSchedulePaid"
            title="Envoyer la preuve de paiement du"
          >
            <UploadDoc fileName="proof" />
          </ModalApp>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default SchedulesPage;

// http://api.taxiora.local:81/back/schedules?

// size=10

// index=1

// endAt=2025-01-16

// sort_order=DESC

// sort_column=paid

// sort_order=ASC&sort_column=created_at&sort_order=DESC&sort_column=paid
