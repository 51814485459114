import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { Group } from "@mantine/core";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import { MODEL } from "Constant";

// Urls
export const API = {
  list: "/back/relayswitches",
  create: "/back/relayswitches",
  get: "/back/relayswitcher/",
  Serial: "/back/relayswitches/serials",
};

// Columns
export const COLUMNS = [
  statusObj("material"),
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: MODEL,
    selector: "model",
    id: "model",
  },
  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, serial_number } = row.original;
      return (
        <Group gap="xs">
          <EditBtn read={API.get + code} extra={serial_number} />
        </Group>
      );
    },
  },
];

// Search
export const SEARCH_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/stop_car",
    getOptions: getTitleCodeLabel,
  },
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/stop_car",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/RELAY-SWITCH",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
