import { getTitleCodeLabel } from "../../../helper/getOptions";
import { ActionIcon, Menu, Tooltip } from "@mantine/core";
import Translate from "Components/Translate";
import { ACTIVE, MODEL, SETTINGS, STATUS } from "Constant";
import { IconDots } from "@tabler/icons-react";
import SettingsEditBtn from "Components/MantineDataTableBtns/SettingsEditBtn";
import SettingsReadBtn from "Components/MantineDataTableBtns/SettingsReadBtn";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import SettingsActiveBtn from "Components/MantineDataTableBtns/SettingsActiveBtn";
import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import SelectGroupBar from "Components/FilterInputs/SelectGroupBar";
import { createdAt, statusObj, updatedAt } from "Constant/objs";

// Urls
export const API = {
  list: "/back/taximeters",
  create: "/back/taximeter",
  edit: "/back/taximeter/",
  get: "/back/taximeter/",
  patch: "/back/tablet/",
  import: "/back/taximeters",
  Serial: "/back/taximeters/serials",
};

// Columns
export const COLUMNS = [
  {
    name: "Number",
    selector: "serial_number",
    enableColumnFilter: false,
  },
  {
    name: MODEL,
    selector: "model",
    id: "model",
    enableColumnFilter: false,
    // Filter: ({ column, header, table }) => {
    //   try {
    //     return (
    //       <SelectGroupBar
    //         url={"/back/models/choices/taximeter"}
    //         column={column}
    //         header={header}
    //         table={table}
    //         // getOptions={getTitleCodeLabel}
    //       />
    //     );
    //   } catch {
    //     return <></>;
    //   }
    // },
  },

  statusObj("material"),
  createdAt,
  updatedAt,

  {
    id: "actions",
    button: true,
    size: 50,
    Cell: ({ row }) => {
      const { code, serial_number, active } = row.original;
      return (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Tooltip label={<Translate>{SETTINGS}</Translate>}>
              <ActionIcon size="lg" variant="subtle" color="gray">
                <IconDots color="gray" />
              </ActionIcon>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            <SettingsReadBtn
              ReadPageBtn
              read={`/taximeter/${serial_number}`}
              disabled
            />
            <SettingsEditBtn read={API.get + code} extra={serial_number} />
            <SettingsActiveBtn patch={API.patch + code} state={active} />
          </Menu.Dropdown>
        </Menu>
      );
    },
  },
];

// Search
// export const SEARCH_ITEMS = [
//   {
//     name: "state",
//     title: "State",
//     inputForm: "SelectInput",
//     options: [
//       {
//         value: "internal_stock",
//         label: "internal_stock",
//         name: "state",
//       },
//       {
//         value: "external_stock",
//         label: "external_stock",
//         name: "state",
//       },
//       {
//         value: "sav",
//         label: "sav",
//         name: "state",
//       },
//       {
//         value: "",
//         label: "all",
//         name: "state",
//       },
//     ],
//   },

//   {
//     name: "status",
//     title: "status",
//     inputForm: "SelectApiInput",
//     required: false,

//     optionsApi: "/back/statuses/material",
//     getOptions: getCodeLabel,
//   },
//   {
//     title: "serial",
//     placeholder: "serial",
//     name: "serial",
//     inputForm: "SimpleInput",
//   },
// ];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/taximeter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Serial number",
    placeholder: "Serial number",
    name: "serial_number",
    inputForm: "SimpleInput",
  },
  {
    title: "Imei",
    placeholder: "Imei",
    name: "imei",
    inputForm: "SimpleInput",
  },
  {
    title: "Imei2",
    placeholder: "Imei2",
    name: "imei2",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Meid",
    placeholder: "Meid",
    name: "meid",
    inputForm: "SimpleInput",
    required: false,
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/taximeter",
    getOptions: getTitleCodeLabel,
    required: false,
  },
  {
    title: "Serial number",
    placeholder: "Serial number",
    name: "serial_number",
    inputForm: "SimpleInput",
    required: false,
    // schema: z.any().optional(),
  },
  {
    title: "Imei",
    placeholder: "Imei",
    name: "imei",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Imei2",
    placeholder: "Imei2",
    name: "imei2",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Meid",
    placeholder: "Meid",
    name: "meid",
    inputForm: "SimpleInput",
    required: false,
  },
];
