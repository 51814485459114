import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Menu, rem, Tooltip, ActionIcon } from "@mantine/core";
import {
  IconTrash,
  IconDots,
  IconCheckbox,
  IconEdit,
} from "@tabler/icons-react";
import { Button as PrimeBtn } from "primereact/button";

import axiosClient from "Services/axiosClient";
import patchItem from "Services/patchItem";
import errorMsg from "Services/errorMsg";

import { CANCEL, CONFIRM, EDIT, SETTINGS } from "Constant";

import Translate from "Components/Translate";
import AddItem from "Components/AddItemWithZod";
import { addAlert } from "Components/Alerts/Alerts";
import SettingsEditBtn from "Components/MantineDataTableBtns/SettingsEditBtn";
import SettingsReadBtn from "Components/MantineDataTableBtns/SettingsReadBtn";

function DropDownBtns({ data }) {
  const { code, to_confirm, to_cancel, to_edit, estimate_number } = data;
  const urlCancel = `/back/estimate/${code}/cancel`;
  const urlConfirm = `/back/estimate/${code}/confirm`;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const cancelTitle = `Annuler le devis N° ${estimate_number}`;

  const cancelFn = async (
    new_data,
    URL_CREATE,
    changeLoadingState,
    success
  ) => {
    console.log(new_data);
    await axiosClient
      .patch(URL_CREATE, new_data)
      .then((res) => {
        addAlert();
        success();
      })
      .catch((error) => {
        errorMsg(error);
      });
    changeLoadingState(false);
  };

  const CancelBtn = () => (
    <PrimeBtn
      onClick={() => setIsOpen(false)}
      severity="secondary"
      type="button"
    >
      <Translate>{CANCEL}</Translate>
    </PrimeBtn>
  );

  return (
    <Fragment>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Tooltip label={<Translate>{SETTINGS}</Translate>}>
            <ActionIcon size="lg" variant="subtle" color="gray">
              <IconDots color="gray" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          {/* <Menu.Label>Application</Menu.Label> */}
          <SettingsReadBtn read={`/estimate/${code}`} />

          <Menu.Item
            leftSection={
              <IconEdit style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => window.open(`/edit-estimate/${code}`, "_blank")}
            disabled={!to_edit}
          >
            <Translate>{EDIT}</Translate>
          </Menu.Item>

          <Menu.Item
            leftSection={
              <IconCheckbox style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => patchItem(urlConfirm)}
            disabled={!to_confirm}
          >
            <Translate>{CONFIRM}</Translate>
          </Menu.Item>

          <Menu.Item
            color="red"
            leftSection={
              <IconTrash style={{ width: rem(14), height: rem(14) }} />
            }
            disabled={!to_cancel}
          >
            <div onClick={() => toggle()}>
              <Translate>{CANCEL}</Translate>
            </div>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle}>{cancelTitle}</ModalHeader>
        <ModalBody>
          <AddItem
            cancelBtn={<CancelBtn />}
            customFn={cancelFn}
            addURL={urlCancel}
            items={[
              {
                title: "Reason",
                placeholder: "Reason",
                name: "reason",
                inputForm: "TextareaInput",
              },
            ]}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
export default DropDownBtns;
