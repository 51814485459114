import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data/values";

import { CREATE_VALUE, EDIT_VALUE } from "Constant";

const ValuesTable = ({ triffCode }) => {
  const list = API.get(triffCode);

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <DataTables
            modalType="createValue"
            newColumns={COLUMNS}
            link={list}
          />
        </CardBody>
      </Card>

      <ModalApp type="createValue" title={CREATE_VALUE}>
        <AddItem addURL={API.create(triffCode)} items={CREATE_ITEMS} />
      </ModalApp>

      <ModalApp type="editValue" title={EDIT_VALUE}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default ValuesTable;
